@import './varibale';
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');
 
.comming_soon {
    height: 100vh !important;

}
button{
    span{
        font-family: 'Cairo';
    }
    font-family: cairo;
}
.comming_soon_bg {

    @media screen and (width < 1429px) {
        justify-content: end;

        div {
            padding-right: 50px;
        }
    }

    @media screen and (width < 1329px) {
        div {
            padding-right: 20px;
        }
    }

    @media screen and (width < 1240px) {
        div {
            padding-right: 10px;
        }
    }

    @media screen and (width < 990px) {
        justify-content: center;

        div {
            padding-right: 0px;
        }
    }

    h1 {
        color: #FFF;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #FFF;

        font-size: 88px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 8.8px;
        text-align: center;
        text-transform: uppercase;

        @media screen and (width < 1240px) {
            font-size: 68px;
        }

        @media screen and (width < 990px) {
            font-size: 88px;
        }

        @media screen and (width < 728px) {
            font-size: 68px;
        }

        @media screen and (width < 560px) {
            font-size: 40px;
            letter-spacing: 2.8px;
        }
    }

    .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;

        .icons_svg {
            svg {
                @media screen and (width < 1400px) {
                    width: 150px;
                    height: 150px;
                }

                @media screen and (width < 1044px) {
                    width: 130px;
                    height: 150px;
                }

                @media screen and (width < 990px) {
                    width: 145px;
                    height: 200px;
                }

                @media screen and (width < 728px) {
                    width: 130px;
                    height: 150px;
                }

                @media screen and (width < 560px) {
                    width: fit-content;
                    height: 100px;
                }


                @media screen and (width < 400px) {
                    width: 60px;
                    height: auto;
                }
            }
        }

        @media screen and (width < 1270px) {
            gap: 0px;
        }

        @media screen and (width < 990px) {
            gap: 25px;
        }

        @media screen and (width < 728px) {
            gap: 0px;
        }

        @media screen and (width < 560px) {
            gap: 15px;
        }

        @media screen and (width < 400px) {
            gap: 10px;
        }
    }

    .contact {

        .phone,
        .email {
            a {
                color: #FFF;
            }
        }
    }
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px #fff solid !important;
    opacity: 1 !important;
    margin-top: 50px !important;
}

.contact_form {
    position: relative;

    .p-button {
        display: flex;
        justify-content: center;
        align-items: center;

        .p-button-label {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 3px;
        }
    }

    .form {

        position: absolute;
        left: 50%;
        transform: translate(-10%);

        @media screen and (width < 1214px) {
            transform: translate(0%);
            left: 0%;
        }

        height: fit-content;
        width: 500px;
        min-height: 732px;
        background: #FFF;
        box-shadow: 1px 1px 44px 0px rgba(0, 0, 0, 0.06);
        z-index: 999 !important;
        border-radius: 10px;
        padding-top: 56px;

        @media screen and (width < 1110px) {
            width: 450px;
        }

        @media screen and (width < 993px) {
            width: 400px;
        }

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 211px;
                height: 86px;
            }
        }
    }
}

.btn_modal {
    display: none;

    @media screen and (width<990px) {
        display: flex;
    }
}

.btn_coming_soon {

    .p-button {

        @media screen and (width < 400px) {
            padding: 5px 10px !important;
            border-radius: 5px;
        }
    }
}

.chat {
    position: fixed;
    top: calc(100vh - 90px);
    width: 150px;
    height: 150px;

    .p-button {
        background-color: $dark !important;
        padding: 10px !important;
        transition: all 0.5s;
        border-radius: 50%;

        &:hover {
            box-shadow: 0px 6.17143px 23.14286px 0px rgba(206, 147, 26, 0.76) !important;
            transform: scale(1.2);
        }

    }
}

.chatAr {
    right: -0px;
    z-index: 99999999 !important;
}

.chatEn {
    left: 0px;
}

.popup {
    display: none !important;
    position: fixed !important;
    top: calc(100vh - 60px) !important;
    right: -100px !important;
    height: 506px;
    z-index: 9999;
    display: flex;
    width: 300.076px;
    padding: 74.419px 16.004px 53.213px 16.004px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 55.884px;
    border-radius: 24.589px;
    background: var(--white, #FFF);
    box-shadow: 0px 4.91429px 25px 0px rgba(206, 147, 26, 0.15) !important;
    overflow: hidden;

    p {
        color: var(--dark, #162A2B);
        text-align: center;
        font-family: Cairo;
        font-size: 14.753px;
        font-style: normal;
        font-weight: 400;
        line-height: 170.4%;
        /* 25.14px */
    }
}

.Chat_Image {
    width: 150px !important;
    height: 150px !important;
}

.popup.openAr {
    display: flex !important;
    top: calc(100vh - 550px) !important;
    z-index: 0;
    right: 60px !important;
}

.popup.openEn {
    display: flex !important;
    top: calc(100vh - 550px) !important;
    z-index: 0;
    left: 26px !important;
}

.popup button {
    background-color: #ccc;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}


.app_services_twining {
    p {
        font-family: Cairo;
        font-size: 30px;
        font-weight: 500; 
        text-align: center;
        color: #000000;
        @media screen and (width < 767px) {
            font-size: 20px;
        }

    }
}

.btn_border_radius{
    .p-button{
        border-radius: 7px; 
        padding: 7px 20px;
        span{
            font-weight: 400;
        }
    }
}
.app_gallerys_pages{

    img {
          object-fit: contain; // Cover the container with the image without distorting it
    }
}
