@import '../../style/varibale';


.app_prodacts_details {
    margin-top: 130px;
    margin-bottom: 150px;

    .app_prodacts_details_container {
        width: 80%;
        margin: auto;
img{
    
    @media screen and (width < 900px) {
      width: 140px;
    }
}
        @media screen and (width < 900px) {
            width: 90%;
        }

        header {
            background-color: $mainColor;
            padding: 5px;
            text-align: center;
            border-radius: 5px;
            user-select: none;

            h2 {
                font-family: Cairo;
                font-size: 27px;
                font-weight: 500;
                color: #FFFFFF;

                @media screen and (width < 900px) {
                    font-size: 20px;

                }
            }
        }
    }
}

.details_content {
    .content {
        margin-top: 15px;

        strong {
            font-family: Cairo;
            font-size: 24px;
            font-weight: 700;
            line-height: 44.98px;

            @media screen and (width < 900px) {
                font-size: 20px;

            }
        }

        p {
            margin-top: 15px;
            color: #393939;
            font-family: Cairo;
            font-size: 18px;
            font-weight: 300;
            line-height: 37.48px;

            @media screen and (width < 900px) {
                margin-top: 5px;
                font-size: 16px;
                line-height: normal;  
            }

        }


        .choose {
            .content {
                span {
                    font-family: Cairo;
                    font-size: 16px;
                    font-weight: 400;
                    color: $mainColor;

                }

                p {
                    font-family: Cairo;
                    font-size: 20px;
                    font-weight: 500;
                    color: #393939;

                    @media screen and (width < 900px) {
                        margin-top: 5px;
                        font-size: 16px;
                        line-height: normal;  
                    }
                }
            }
        }
    }
}

.images_product_details{
    img{
        width: 300px !important;
        height: calc(300*1.56)px;
        object-fit: contain;
    }
}