@import '../../../style/varibale';

.app__brnad {
    padding: 90px 0;

    .app__brnad_container {
        width: 80%;
        margin: auto;

    }



    .brands_img {
        @media screen and (width <900px) {
            width: 100%;
        }

        .brand {
            border-radius: 15px;
            background: #FFF;
            box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.12);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 400px;
            flex-shrink: 0;

            @media screen and (width < 1000px) {
                height: fit-content !important;
                margin-top: 50px;
            }

            @media screen and (width < 700px) {
                height: 150px !important;

            }

            img {
                width: 70% !important;
                height: 229px !important;
                flex-shrink: 0 !important;
                object-fit: contain;

                /*        @media screen and (width < 1530px) {
                    font-size: 20px; 
                } */

                @media screen and (width < 1000px) {
                    width: 150px !important;

                }

                @media screen and (width < 700px) {
                    width: 100px !important;

                }
            }

        }
    }

}

.content_about {
    p {
        color: #000;

        font-family: Cairo;
        font-size: 22px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize; 
        @media screen and (width < 1530px) {
            font-size: 24px;
        }

        @media screen and (width < 900px) {
            font-size: 20px;

        }
    }

    span {
        color: #A3A3A3;

        font-family: Cairo;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 1530px) {
            font-size: 20px;
        }

        @media screen and (width < 900px) {
            font-size: 18px;

        }
    }
}

.app__component {
    margin-bottom: 150px;

    @media screen and (width < 900px) {
        padding: 0 !important;
        margin: 0 !important;
    }

    padding: 90px 0;


    .app__component_container {
        width: 80%;
        margin: auto;

        @media screen and (width < 1200px) {
            width: 90% !important;

        }

        .chef {
            display: flex;
            justify-content: center;
            align-items: flex-end; // هذا سيضع الصورة في الأسفل
            position: relative;
            height: 100%; // تأكد من أن العنصر الأم له ارتفاع محدد

            .chef_bg {
                position: absolute;
                width: 500px;
                /* أو أي عرض تريده */
                aspect-ratio: 3 / 2;
                /* يمكنك تغيير النسبة حسب رغبتك */
                flex-shrink: 0;
                background: #F68B1F;
                order: 1;
                bottom: 0;
                left: 25%;

                @media screen and (width < 1530px) {
                    width: 400px;
                    left: 35%;
                }

                @media screen and (width < 1100px) {
                    width: 400px;
                    left: 30%;
                }

                @media screen and (width < 767px) {
                    width: 350px;
                    left: 30%;
                }

                @media screen and (width < 530px) {
                    width: 300px;
                }

                @media screen and (width < 450px) {
                    left: 17%;
                }

                @media screen and (width < 473px) {
                    left: 15%;
                }

                @media screen and (width < 415px) {
                    left: 20%;
                }
            }

            .chef_bg2 {
                position: absolute;
                width: 500px;
                aspect-ratio: 3 / 2;
                /* يمكنك تغيير النسبة حسب رغبتك */
                flex-shrink: 0;
                background: rgba(119, 111, 104, 0.46);
                backdrop-filter: blur(44px);
                order: 2;
                bottom: 50px;

                @media screen and (width < 1530px) {
                    width: 400px;
                }

                @media screen and (width < 767px) {
                    width: 350px;
                }

                @media screen and (width < 530px) {
                    width: 300px;
                }

                @media screen and (width < 450px) {
                    left: 9%;
                }

                @media screen and (width < 450px) {
                    left: 6%;
                }

                @media screen and (width < 473px) {
                    left: 3%;
                }

                @media screen and (width < 415px) {
                    left: 6%;
                }
            }

            img {
                position: absolute;
                order: 3;
                bottom: 0;
                width: 400px;
                position: relative; // أو قد تحتاج إلى إزالة هذه الخاصية تمامًا
                width: 100%; // أو أي عرض تريده
                max-width: 400px; // للتحكم في العرض الأقصى للصورة

                @media screen and (width < 1530px) {
                    width: 300px;
                }

                @media screen and (width < 767px) {
                    width: 290px;
                }

                @media screen and (width < 530px) {
                    width: 250px;
                }
            }
        }

        .distribution {
            @media screen and (width < 767px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            img {
                width: 600px;
                height: calc(150*1.197872396940076)px;

                @media screen and (width < 767px) {
                    width: 290px;
                }

                @media screen and (width < 530px) {

                    width: 300px;
                    object-fit: contain;
                }
            }
        }
    }
}

.app_chif {
    @media screen and (width < 900px) {

        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 150px;
    }
}

.content_about {
    p {
        color: #000;

        font-family: Cairo;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 1530px) {
            font-size: 24px;
        }

        @media screen and (width < 900px) {
            font-size: 18px;
        }
    }

    span {
        color: #A3A3A3;

        font-family: Cairo;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; 
        text-transform: capitalize;

        @media screen and (width < 1530px) {
            font-size: 20px;
        }

        @media screen and (width < 900px) {
            font-size: 16px;
        }
    }
}


.app_about_brand {

    background-color: #000;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        padding: 0 90px;

        @media screen and (width < 980px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        @media screen and (width < 600px) {
            padding: 0 20px;
        }

        h3 {
            color: #FFF;

            font-family: Cairo;
            font-size: 55px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (width < 700px) {
                font-size: 30px;
            }

            @media screen and (width < 1231px) {
                font-size: 40px;
                padding-top: 20px;
            }

            @media screen and (width < 980px) {
                text-align: center;
            }

            @media screen and (width < 600px) {
                font-size: 37px;
            }
        }

        p {
            color: #FFF;

            font-family: Cairo;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (width < 1090px) {
                font-size: 20px;
            }

            @media screen and (width < 980px) {
                text-align: center;
            }

            @media screen and (width < 600px) {
                font-size: 16px;
            }
        }
    }

    .content_img {
        @media screen and (width < 980px) {
            justify-content: center !important;
            margin-top: 40px;
        }
    }
}

.ap_flex_sm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}