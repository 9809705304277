@import 'style/varibale';

.app_about_master {
    .header {
        @media screen and (width < 900px) {
            margin-top: 80px;

        }
    }

    .btn1 {
        margin-bottom: 20px;

        .p-button {
            background-color: $mainColor;
            padding: 10px 50px;
        }
    }

    .Container_content {
        width: 85%;
        margin: auto;
        margin-top: -23px;
        @media screen and (width < 767px) {
            margin-top: -19px;

            .p-button{
                padding: 6px 50px;
            } 
        }

        p {
            font-family: Cairo;
            font-size: 20px;
            font-weight: 400;
            line-height: 45px;
            letter-spacing: 0em;


            @media screen and (width < 767px) {
                font-size: 16px;
                line-height: normal;
            }

        }
    }

    .about_us_contents {
        width: 87%;
        margin: auto;
        @media screen and (width < 767px) {
            width: 100%;
            .p-button{
                padding: 6px 50px;
            } 
        }

        .content {
            width: 90%;
            margin: auto;
            margin-top: -23px;

            p {
                font-family: Cairo;
                font-size: 20px;
                font-weight: 400;
                line-height: 36.22px;  
            }
        }
    }

    .Container_content2 {
        margin-top: 120px;

        @media screen and (width < 767px) {
            margin-top: 80px;
        }

        ul {
            display: flex;
            gap: 20px;
            flex-direction: column;

            li {
                font-family: Cairo;
                font-size: 20px;
                font-weight: 400;
                color: #393939;
                line-height: 45px;

                @media screen and (width < 767px) {
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
    }

    .app_service {
        background-color: transparent !important;

        padding: 10px 7px !important;

        .app_service_flex {
            :hover {
                background: #FFCEAD57;
            }
        }

        .app_service_flex {
            :hover {
                background: #FFCEAD57;
            }
        }

        .service {
            padding: 60px 7px;

            p {
                color: #000;
            }

            .num {
                color: $mainColor;
            }

            .title {
                color: #000;
            }
        }
    }
}

.app_service {
    background-color: transparent;

    padding: 10px 7px !important;

    .app_service_flex {
        :hover {
            background: #FFCEAD57;
        }

        span,
        .icons,
        p {
            &:hover {
                background: transparent !important;
            }
        }
    }
}

[dir="ltr"] .Container_content2 {
    padding-left: 100px;

    @media screen and (width < 767px) {
        padding-left: 30px;

    }
}

[dir="rtl"] .Container_content2 {
    padding-right: 130px;

    @media screen and (width < 767px) {
        padding-right: 30px;


    }
}

.conlection_img {
    margin-top: 150px;
    position: relative;

    .img_sm {
        position: absolute;
        right: -25%;
        bottom: 10%;

        @media screen and (width < 767px) {
            width: 150px;
            right: -15%;
            bottom: 10%;
        }
    }

    .img_lg {
        @media screen and (width < 767px) {
            width: 250px;

        }
    }
}

.conlection_img_master {
    background-image: url('../../assets/svg/bgAbout2.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.blog_details_data {
    p {
        font-family: Cairo;
        font-size: 30px;
        font-weight: 400;
        line-height: 46.22px ;
        @media screen and (width < 900px) {
            line-height: normal ; 
            font-size: 16px !important;

        }
    }

}

.blog_details_data_Bakery_industry {
    p {
        font-family: Cairo;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        margin-top: 30px;

        @media screen and (width < 900px) {
            margin-top: 20px !important ;

        }

        @media screen and (width < 900px) {
            line-height: normal ; 
            font-size: 16px !important;

        }

    }


}