@import '../../style/varibale';

.app_category {
    margin: 90px 0;

    .app_category_container {
        width: 80%;
        margin: auto;
        @media screen and (width < 900px) {
            width: 95%;
        }
    }

    header {
        .app_category_header {
            background: #FFF5EC;
            border-radius: 5px;
            padding: 5px 20px;
        }
    }

    .category-details-col {
        .category-details {
            img {
                width: 100px;
                height: 100px;
                object-fit: contain;
            }

            .content {
                strong {
                    font-family: Cairo;
                    font-size: 35px;
                    font-weight: 700;
                }

                p {
                    font-family: Cairo;
                    font-size: 20px;
                    color: #393939;
                }
            }
        }
    }

    .app_category_main_image {
        margin-bottom: -150px;
        @media screen and (width < 900px) {
            margin-bottom: 0;
        }
        img {
            width: 200px;
            height: 353px;
            object-fit: contain;
        }
    }
}

.choose_category {
    margin-top: 150px;
overflow: hidden;
    .navigation-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

      @media screen and (width < 900px) {
           flex-direction: column;
           gap: 15px;
        }
        .ant-btn {
            margin: 0 10px;
        }


    }

}

.btn_main {
    display: flex;
    gap: 30px;
    align-items: center;

    .title {
        color: $mainColor;
        user-select: none;
        @media screen and (width < 900px) {
            display: none ;
         }
    }
}

.app_category_card {
    .ant-card-cover {
        height: 350px;
        transition: border 0.3s ease, background 0.3s ease; // Smooth transition for border and background
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 50%;
            height: 90%;
            object-fit: contain;
            transition: transform 0.7s ease !important;
        }
    }

    &:hover {
        .ant-card-cover {
            border: 1px solid #FF8C40;
            background: #FFF5EC;

            img {
                transform: scale(1.05);
            }
        }
    }

    .content {
        .title {
            strong {
                font-family: Cairo;
                font-size: 18px;
                font-weight: 700; 
            }
        }
        .des{
            p{
                color: #6A6A6A;
                font-family: Cairo;
                font-size: 16px;
                font-weight: 300;
                line-height: 29.98px; 
                
            }

        }
    }
}