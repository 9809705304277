@import '../../../style/varibale';


// slider
.slick-dots li button:before,
.slick-dots li.slick-active button:before {
    color: transparent !important;
}

// Override slick dots color
.ant-carousel .slick-dots li button {
    background-color: #fff !important;
}

// Override slick dots active button color
.ant-carousel .slick-dots li.slick-active button {
    background-color: $mainColor !important;
}

.slide-container {
    position: relative;
    height: 100vh;

    @media screen and (width<900px) {
        height: fit-content;
        margin-top: 80px;
    }
}

.overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #0003 !important;

    z-index: 2;
}