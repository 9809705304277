@import '../../style/varibale';

.app__footer {
    border-top: 1px solid  $mainColor;
    background-color:#FFFBF7 ;
    padding-top: 50px;
    ul {
        margin: 0px !important;
        padding: 0 !important;
        list-style: none !important;
    }

    p {
        color: #000;
       
        font-family: Cairo;
        font-size: 16px;
        font-style: normal; 
        user-select: none;
        margin-top: 5px;
        opacity: 0.8;
        @media screen and (width < 960px) {
            // font-size: 25px; 
        }
    }
    .nav-link {

        color: #000;
        font-family: Cairo;
        font-size: 16px !important; 
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .social {
        display: flex;
        gap: 20px;

        position: relative;

        svg,
        .facebook {
            width: 22px !important;
            height: 22px !important;
        }



    }

    .footer_Links {
        h1 {
            color: $mainColor;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .footer_ui {
            ul {
                display: flex;
                gap: 16px;
                margin-top: 20px !important;
                flex-direction: column;

                li {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    cursor: pointer;
                    line-height: 197.4%;
                    letter-spacing: 0.63px;
                    font-family: cairo;

                    a {
                        color: #000 !important;

                    }


                    .active_main_color {
                        color: rgba($color: $mainColor, $alpha: 0.8) !important;
                    }
                }
            }
        }
    }
}

.hr__footer {
    margin-top: 0px;
    color: #000 !important;
}

.footer__buttom {
    display: flex;
    justify-content: center;
    align-items: center;

    a, span {
        color: var(--white, #000);
        font-family: Cairo;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 

        @media screen and (width < 500px) {
            font-size: 12px;
        }
    } 
}

.gap-sm-8 {
    @media screen and (width < 980px) {

        gap: 50px;
    }
}

.payment_icons {
    svg {
        width: 40px;
        height: 40px;
    }
}

.rightClick{
    color: $mainColor !important;
    font-size: 20px !important;
}



.top-bar { 
    margin-top: 20px !important; 

}

.top-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;  
    @media screen and (width < 800px) {
        max-width: 95%;


    }

    @media screen and (width < 800px) {
        max-width: 90%;


    }

    /* Or your maximum layout width */
    .change-lang {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        user-select: none;

        svg {
            width: 16px;
            height: 16px;
        }

        .text {
            color: $dark;
        }

        .lang {
            path {
                fill: $dark !important;
            }
        }
    }
}

.logo-icons {
    display: flex;
    align-items: center;

    @media screen and (width < 600px) {
        display: none;
    }

    span {
        cursor: pointer;

        svg {
            width: 14px;
            height: 14px;

            path {
                fill: $dark !important;
            }
        }
    }

    .instagram-icon,
    .linkedin-icon {
        svg {
            width: 12px;
            height: 12px;
        }
    }
}

.logo-placeholder,
.contact-icon,
.twitter-icon,
.facebook-icon,
.instagram-icon,
.linkedin-icon {
    background-color: #444;
    /* Placeholder for icon background */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin: 0;
    transition: all 0.6s;
    &:hover{
        background-color: rgba($color: $mainColor, $alpha: 0.5);
    }

}

.top-bar-text {
    font-size: 1rem;
}

.social-media-icons {
    display: flex;
    flex-direction: column;
    align-items: inherit !important; 
    gap: 15px;

    span {
        cursor: pointer;
        svg {
            width: 14px;
            height: 14px;
        }
    }

    .instagram-icon,
    .linkedin-icon {
        svg {
            width: 12px;
            height: 12px;
            margin: 0;
        }
    }
} 
.title_social{
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    align-items: center;
    width: 100%;

    span{
        color: $dark;
        font-family: cairo; 
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: 197.4%;
        letter-spacing: 0.63px;
    }
}

.icon_footer{
    svg{
        // width: 20px;
        // height: 150px;
    }
}