@import '../../../style/varibale';

.app__about_home {
    background-image: url('../../../assets/svg/bgAbout.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: local;


    .app__about_container {
        width: 80%;
        margin: auto;


        @media screen and (width < 1200px) {
            width: 90% !important;

        }
    }

    padding: 150px 0;

    @media screen and (width < 900px) {
        padding: 20px 0;
    }

    .content_about {
        margin-top: 30px;

        @media screen and (width < 1530px) {
            margin-top: 20px;
        }

        p {
            color: #000;

            font-family: Cairo;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (width < 1530px) {
                font-size: 24px;
            }

            @media screen and (width < 900px) {
                font-size: 20px;

            }
        }

        span {
            color: #A3A3A3;

            font-family: Cairo;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (width < 1530px) {
                font-size: 20px;
            }

            @media screen and (width < 900px) {
                font-size: 18px;

            }
        }


    }


}

@media screen and (width < 900px) {
    .p-button {
        padding: 5px 15px;

        span {
            font-weight: 600;
        }
    }

}

.app__summary_title {

    .header {
        h3 {
            color: #F68B1F;
            font-family: $fontBase !important;
            font-size: 55px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            color: $mainColor;
            font-weight: 700;
            font-family: 'Roboto', sans-serif;
            position: relative;
            width: fit-content;
            z-index: 1;


            @media screen and (width < 1530px) {
                font-size: 50px;
            }

            @media screen and (width < 767px) {
                font-size: 40px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%);
                background: rgba(200, 200, 200, 0.35);
                border-radius: 11px;
                width: 250px;
                height: 21px;
                z-index: -1;

                @media screen and (width < 767px) {
                    height: 20px;
                    bottom: 0%;
                }

                @media screen and (width < 1530px) {
                    width: 100%;
                    height: 18px;

                }

                @media screen and (width < 767px) {
                    height: 16px;
                }
            }
        }
    }

    p {
        font-size: 30px;

    }
}



.image_right_sm {
    padding: 0 60px !important;

    @media screen and (width < 767px) {
        padding: 0 20px !important;
        margin: 0 !important;
    }
}
.image_right1 {
    width: 300px;
    height: calc(150*1.565610859728507)px;

    @media screen and (width < 767px) {
        width: 160px;
        height: calc(150px*1.9) ;


    }
}
.image_right2 {
    width: 230px !important;
    height: calc(100*1.565610859728507)px !important;

    @media screen and (width < 767px) {
        width: 130px !important;


    }
}

.image_right3 {
    width: 230px !important;
    height: calc(100*0.6839080459770115)px !important;

    @media screen and (width < 767px) {
        width: 130px !important;

    }
}