@import '../../../style/varibale';

.app_clients_home {
    background-image: url('../../../assets/svg/bg.svg');
    font-family: $fontBase !important;

    .app_clients_home_container {
        width: 90%;
        margin: auto;
    }

    label {
        color: #FFF !important;
    }

    .ant-form-item-explain-error {
        color: #ffffff74; // لون النص الأبيض لجعل النص واضح على الخلفية البرتقالية
    }

    .client_span {
        color: #FFF;
        font-family: $fontBase !important;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 767px) {
            font-size: 25px;

        }
    }

    .p-button {
        display: flex;
        gap: 10px;
        background-color: $dark;
        border: none;
        font-family: $fontBase !important;

        .p-button-label {
            color: #FFF;
            font-family: $fontBase;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    textarea,
    input {
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.35);
        background: #FFF;
        padding: 10px;
    }
}

.label-style {
    color: #FFF;
}

.app_clients_home {
    padding: 2rem 0;
}

.app_clients_home_container_home {
    padding: 2rem;
    background: #f68b1f7c; // لون خلفية النموذج البرتقالية
    border-radius: 10px;
 
    .client_span {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .form {
        width: 100%;
    }

    .input-style {
        width: 100%;
    }

    .label-style {
        font-weight: bold;
    }

    .ant-form-item {
        margin-bottom: 1rem;
    }


    .btn_submit {
        button {
            border: 1px solid #FFFFFF;
            background: #2B2B2B;
            width: 140px;
            height: 50px;
            border-radius: 5px !important;
            border: 1px 0px 0px 0px;
            font-family: cairo;
            transition: all 0.5s;

            &:hover {
                color: #2B2B2B !important;
                border: 1px solid #2B2B2B;

            }
        }

    }
}