@import '../../style/varibale';

.app_products {
  background: rgba($color: $mainColor, $alpha: 0.05);

  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 90px;
  padding-bottom: 90px;
}


.app_products {
  .product-row {

    .product-image-col {
      display: flex;
      justify-content: center;

    }

    .product-details-col {
      display: flex;
      justify-content: center;
      flex-direction: column; // Stacks children vertically
    }
  }

  .brand_header {

    height: 250px;
    width: 80%;
    display: flex;
    gap: 40px;

    @media screen and (width < 900px) {
      flex-direction: column !important;
      align-items: center;
      width: 100% !important;
      margin-top: 50px;
      height: auto;
    }

    .img_brand {
      img {
        width: 200px !important;
        height: 260px;
        object-fit: contain;
      }
    }

  }

  .product-details {
    @media screen and (width < 900px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 150px !important;
        object-fit: contain !important;
      }
    }

    P {
      font-family: Cairo;
      font-size: 18px;
      font-weight: 400;
      line-height: 40px;
   
      @media screen and (width < 900px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
   
      }
    }

    img {
      width: 300px;
      height: 126.33px;
      object-fit: contain;
    }

    h2 {
      color: #ff5722; // Example color, adjust as needed
    }

    button {
      background-color: transparent;
      color: #000;
      border: none;
      padding-bottom: 8px;
      border-bottom: 1px solid $mainColor;
      cursor: pointer;
      margin-top: 20px;
      font-weight: bolder;

      font-family: Cairo;
      font-size: 16px;
      font-weight: 500;

      @media screen and (width < 900px) {
      
      }


    }
  }

  .content_brand {
    .title {


      strong {
        font-family: Cairo;
        font-size: 28px;
        font-weight: 700;

      }

      p {
        font-family: Cairo;
        font-size: 18px;
        font-weight: 300;
        line-height: 40px;
        color: #393939;


      }

    }
  }
}

.products_items {
  margin-top:0px;
  margin-bottom: 150px;

  .custom-card-meta .ant-card-meta-detail {
    text-align: center;
  }

  .custom-card .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* يركز المحتويات أفقيًا */
    justify-content: center;
    /* يركز المحتويات عموديًا */
    text-align: center;
    /* يركز النصوص */
  }

  .custom-card-content {
    strong {
      margin-bottom: 8px;
      font-family: Cairo;
      font-size: 22px;
      font-weight: 700;
      color: #3A3A3A;


    }

    p {
      margin-bottom: 4px;
      font-family: Cairo;
      font-size: 20px;
      font-weight: 400;
      color: $mainColor;
    }

    span {
      font-family: Cairo;

    }
  }
}

.brands_prodacts_img {
  width: 93px;
  height: 145px;
  object-fit: contain;

}