@import 'style/varibale';

.app_contact_master {
    margin-top: 90px;

    .app_contact_master_page {
        @media screen and (width < 900px) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .app_clients_home_container {
        overflow: hidden;

        .app_form_contactus {
            background: #F9F9F9;
            margin: auto;
            padding: 0px 100px;
            padding-bottom: 80px;

            @media screen and (width<767px) {
                padding: 0px 40px;

            }

            .label-style {
                color: #000;


            }

            input::placeholder {
                opacity: 0.5;
                color: #000;
                font-size: 14px;
            }

            .p-button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                background-color: $mainColor;
                border-radius: 3px !important;
            }

            @media screen and (width < 900px) {
                .p-button {
                    padding: 5px 15px;

                    span {
                        font-weight: 600;
                    }
                }

            }
        }

        .app_form_contactus_light {
            background-color: #fff;
            padding: 0 60px;

            @media screen and (width < 767px) {
                padding: 0px;
                width: 85% ;
                margin: auto; 
            }

            .contant {
                display: flex;
                flex-direction: column;

                .client_span {
                    color: $mainColor;
                    font-family: Cairo;
                    font-size: 60px;
                    font-weight: 700;
                    line-height: 120px;
                    letter-spacing: 0em;

                    @media screen and (width < 767px) {
                        font-size: 40px;
                    }

                }

                p {
                    font-size: 20px;
                    font-weight: 300;  
                    @media screen and (width < 767px) {
                        font-size: 16px;
                    }

                }
            }



        }

        .calling {
            display: flex;
            ;
            justify-content: space-between;
            align-items: center;

            span {
                display: flex;
                gap: 7px;
                align-items: center;

                i {
                    color: $mainColor;
                }

                a {
                    color: #000;
                    opacity: 0.9;
                    font-size: 15px;

                }
            }
        }
    }
}

.hr hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px #00000070 solid !important;
    opacity: 1 !important;
    margin-top: 50px !important;
}

[dir="rtl"] .app_form_contactus_light .contant p {

    padding-left: 50px;
}

[dir="ltr"] .app_form_contactus_light .contant p {
    padding-right: 50px;
}

.Container_img {
    width: 100%;
    height: auto;

    img {

        @media screen and (width < 900px) {
            width: 150px;
        }

        @media screen and (width < 767px) {
            display: none;
        }
    }
}